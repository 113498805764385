<template>
  <div class="operationLog">
    <a-spin :indicator="indicator" :spinning="spinning">
    <div class="select_Info">
      <div>
        状态：&nbsp;
        <a-radio-group  @change="openChange" button-style="solid">
          <a-radio-button :value= 1 >
            全部
          </a-radio-button>
          <a-radio-button :value= 100 >
            医护人员
          </a-radio-button>
          <a-radio-button :value= 200 >
            医学生
          </a-radio-button>
          <a-radio-button :value= 300 >
            其他
          </a-radio-button>
        </a-radio-group>
      </div>
      <div style="margin-left: 100px">
          操作时间: &nbsp;<a-range-picker v-model="time" @change="dateChange" style="width: 250px"   />
        <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
        <a-button style="margin-left: 10px"  @click="searchReset">重置</a-button></div>
    </div>
    <div class="table_Info">
      <a-button  @click="excelOk"><a-icon type="file-excel" style="color: #00CC33" />批量导出</a-button>
      <a-table :columns="columns" :data-source="InfoTable" style="min-height: 450px" :rowKey="(record,index)=>{return index}"  :pagination="false"
      >
        <span slot="type" slot-scope="item">
          <span>{{item.type === 1001 ? '医生': item.type === 1002 ? '护士' :
              item.type === 1003 ? '医技:' : item.type === 1004 ? '药师' : item.type === 1005 ?
                  '学生' :item.type === 1006 ? '其他医务从业者' :'非医务从业者' }}
          </span>
        </span>
        <span slot="time" slot-scope="item">
          {{ item.created_time | formatDate('YYYY-MM-DD HH:mm:ss')}}
        </span>
      </a-table>
      <div  style="display: flex;justify-content: flex-end;right: 35px;margin-top: 15px;">
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      @change="tableChange" />
      </div>
    </div>
    </a-spin>
  </div>
</template>

<script>
import {getMemberLog} from "@/service/operationLog";
import moment from "moment";
export default {
  name: "operationLog",
  data(){
    return{
      searchName:'',
      spinning: false,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      pagination:{
        total:0,
        current: 1,
        pageSize:10,
      },
      columns:[
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '医院',
          dataIndex: 'hospital_name',

        },
        {
          title: '科室',
          dataIndex: 'hospital_offices',

        },
        {
          title: '职称',
          dataIndex: 'job_title',

        },
        {
          title: '身份',
          scopedSlots: { customRender: 'type' },

        },
        {
          title: '操作模块',
          dataIndex: 'modular'

        },
        {
          title: '操作',
          dataIndex: 'module_function'

        },
        {
          title: '内容ID',
          dataIndex: 'contentId'

        },
        {
          title: '访问终端',
          dataIndex:'channel'

        },
        {
          title: '操作时间',
          scopedSlots: { customRender: 'time' },

        },

      ],
      InfoTable:[],
      timeStart:null,
      timeEnd:null,
      memberType:'',
      time:[],
    }
  },
  filters: {
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"操作日志")
  },
  mounted() {
    this.getMemberLogs()
  },
  methods:{
    async getMemberLogs(pageNum,type,start,end){
      this.spinning = true
      const Info = await  getMemberLog(pageNum,type,start,end)
      if (Info.code === 0){
        this.InfoTable =Info.data.wyy
        this.pagination.total = Info.data.count
      }
      else {
        this.$message.warn('接口连接失败，请联系管理员！')
      }
      this.spinning = false
    },
    tableChange(e){
      this.getMemberLogs(e,this.memberType,this.timeStart,this.timeEnd)

    },
    //时间搜索
    searchOk(){
      this.getMemberLogs(1,this.memberType,this.timeStart,this.timeEnd)
      this.pagination.current = 1
    },
    searchReset(){
      this.getMemberLogs()
      this.pagination.current = 1
      this.time = []
    },
    async excelOk() {
      const url = 'https://yun-new-admin-api.yunicu.com/v3/logMemberExcel'
            +'?start=' + this.timeStart + '&end=' + this.timeEnd + '&type=' +this.memberType
      window.open(url)
    },
    //身份查询
    openChange(e){
      this.memberType = e.target.value
      switch (e.target.value){
        case 1:
          this.getMemberLogs()
            this.pagination.current = 1
          break;
        case 100 :
          this.getMemberLogs(1,100)
          this.pagination.current = 1
          break;
        case 200:
          this.getMemberLogs(1,200)
          this.pagination.current = 1
          break;
        case 300:
          this.getMemberLogs(1,300)
          this.pagination.current = 1
          break;
      }
    },
    dateChange(date, dateString){
      this.time = dateString
      this.timeStart= moment(dateString[0]).format("YYYY-MM-DD 00:00:00")
      this.timeEnd = moment(dateString[1]).format("YYYY-MM-DD 23:59:59");
    },
  },

}
</script>


<style lang="scss" scoped>
.operationLog{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .select_Info{
    border-radius: 3px;
    background-color: white;
    height: 80px;
    width: 98%;
    margin: 0 auto;
    line-height: 80px;
    display: flex;
    padding: 0 15px 0 15px;
  }
  .table_Info{
    height:100%;
    width: 98%;
    margin: 10px auto;
    padding: 15px;
    min-height: 400px;
    background-color: white;
  }
}
</style>